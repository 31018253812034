import React from "react";
import {Link} from "gatsby"
import Img from "gatsby-image";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import VideoGallery from './video-gallery';

import * as styles from './event-information.module.css'

// TODO: The content on this page does not look entirely centered? (at least not on mobile)

export default function EventInformation(props) {
 
  function sortByName(s1, s2) {
    if(s1['title'] && s1['title'].includes("Head of")) {
      return -1;
    } else if (s2['title'] && s2['title'].includes("Head of")) {
      return 1;
    }
    if ((s1.name > s2.name)) {
      return 1;
    } else if (s1.name < s2.name) {
      return -1;
    } else {
      return 0;
    }
  };

  const event = props.event
  return (
    <div>
      <Img
        class="hero-image"
        className={styles.banner_image}
        fluid={event.image.fluid}
        alt={event.name}
      />
      <div className={styles.tedxki_container}>
        <div>
          <div className="d-flex justify-content-center anchorHoverUnderscore">
            <div className={styles.event_description_container} dangerouslySetInnerHTML={{__html: event.description.childMarkdownRemark.html,}} />
          </div>
        </div>
        {
          event.relatedVideos &&
          <div class="mt-2">
            <h3 class="mb-4 text-center">Videos from the event</h3>
            <VideoGallery videos={event.relatedVideos}></VideoGallery>
          </div>
        }

        {
          event.speakers &&
          <div className={styles.speakers_container}>
            <h1 id="speakers">SPEAKERS</h1>
            {event.speakers.map((speaker, i) => {
              return (
                <div class="my-4">
                  <div className={styles.speaker} style={{ direction: i%2 === 0 ? 'ltr' : 'rtl' }}>
                    <Img
                      className={styles.speaker_photo}
                      fluid={speaker.photo.fluid}
                      alt={speaker.name}
                    />
                    <div style={{ direction: 'ltr' }} class="mt-3">
                      <h4><Link to={speaker.linkedInProfileLink} target="_blank" rel="noreferrer">{speaker.name}</Link></h4>
                      <p className={styles.job_title}>{speaker.jobTitle}</p>
                      <p className={styles.speaker_bio}>
                        {speaker.bio.childMarkdownRemark.internal.content}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }

{
          event.hosts &&
          <div className={styles.speakers_container}>
            <h1 id="hosts">HOSTS</h1>
            {event.hosts.map((host, i) => {
              return (
                <div class="my-4">
                  <div className={styles.speaker} style={{ direction: i%2 === 0 ? 'ltr' : 'rtl' }}>
                    <Img
                      className={styles.speaker_photo}
                      fluid={host.photo.fluid}
                      alt={host.name}
                    />
                    <div style={{ direction: 'ltr' }} class="mt-3">
                    <h4><Link to={host.linkedin} target="_blank" rel="noreferrer">{host.name}</Link></h4>
                      <p className={styles.speaker_bio}>
                        {host.bio.childMarkdownRemark.internal.content}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }


        {
          event.performers && 
          <div>
            <h1 id="performers" className={styles.h1_performers}>PERFORMERS</h1>
            <Container fluid={true}>
              <Row lg={3} xs={1} className="mt-3 justify-content-center">
                {event.performers.map(performer => {
                  return (
                    <Col className="mx-5">
                      <Img
                        className={styles.performer_photo}
                        fluid={performer.photo.fluid}
                        alt={performer.name}
                      />
                      <div className={styles.speaker_info} class="mt-3">
                        <h4>{performer.name}</h4>
                        <strong>{performer.title}</strong>
                        <p className={styles.speaker_bio}>
                          {performer.bio.childMarkdownRemark.internal.content}
                        </p>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </div>
        }

        {
          event.teams && 
          <div>
            <h1>The Team</h1>
            <Container fluid={true}>
            {event.teams.map(t => {
              return (
              <div class="mt-4 mb-2" key={t.id}>
                <h3>{t.name}</h3>
                <Row className="justify-content-center">
                  {t.teamMembers.sort(sortByName).map(tm => {
                    return (
                      <Col lg={3} xs={10} className="mb-3">
                        {
                        <Card key={tm.id}>
                          
                          <Card.Img variant="top"
                            src={tm.photo.file.url}
                            alt={tm.name}
                          />
                          <Card.Body>
                            <Card.Title className="text-center">
                              <strong>{tm.name}</strong>
                              <p>{tm.title}</p>
                            </Card.Title>
                          </Card.Body>
                        </Card>
                        }
                      </Col>
                    )
                  })}
                </Row>
              </div>
              )
            })}
            </Container>
          </div>
        }
      </div>
    </div>
  )

};
